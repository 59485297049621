import React, { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"
import Columns from "../components/columns"
import sal from "sal.js"
import heel1 from "../../static/images/1.png"
import heel2 from "../../static/images/2.png"
import heel3 from "../../static/images/3.png"
import heel4 from "../../static/images/insolehp.png"

const IndexPage = () => {
  useEffect(() => {
    // Update the document title using the browser API
    sal({
      threshold: 1,
    })
  })

  return (
    <Layout>
      <SEO title="Stimulate | home page" />

      <section
        className=" hero is-fullheight has-bg"
        style={{
          backgroundPosition: "center",
          background: "linear-gradient(135deg, #89d3d7 0%,#62c5ca 100%)",
        }}
      >
        <div className="hero-body">
          <div className="container">
            <Columns classes="is-vcentered">
              <div className="column is-7 is-relative heels">
                <img src={heel4} className="hidethis" />
                <img src={heel1} />
                <img src={heel2} />
                <img src={heel3} />
              </div>
              <div className="column is-half" style={{ paddingRight: "10%" }}>
                <h2 className="is-size-0">Habit changing insole system.</h2>
                <br />
                <p className="is-size-4">
                  We help brands increase their sales by giving them the world's
                  most comfortable insole system for high heel footwear.
                </p>
              </div>
            </Columns>
          </div>
        </div>
      </section>

      {/* <Section>
      <h2 className='is-size-1  has-text-centered' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">Comfort without<br></br>losing aesthetics.</h2>
      <div className = 'is-relative has-text-centered'>
        <div className = 'left-text'  data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">
          <p className = 'is-size-4'>We have replaced rigid steel with high performance plastic and made world’s thinnest insole board for high heels.<br /><br /><span className = ''>It is lightweight, more stable and offers even pressure distribution.</span></p>
        </div>
        <div className = 'right-text'  data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">
        <p className = 'is-size-4'>World’s thinnest insole for high heels enabled us to use dual purpose cushioning in 6mm thickness.</p>
        </div>
        <br /><br /><img src='./images/2.png' style={{width : '50%'}}  data-sal="slide-up" data-sal-delay="0" data-sal-duration="900" />
      </div>
    </Section> */}

      <Section classes="is-medium">
        <Columns classes="is-variable is-8 is-vcentered has-marginbottom-2">
          <div className="column has-marginbottom-2">
            <h2 className="is-size-0">
              Comfort without
              <br />
              losing aesthetics.
            </h2>
          </div>
          <div className="column has-marginbottom-2">
            <img src="./images/disintegrated.png" className="disimage" />
          </div>
          <img
            alt=""
            src="./images/sep.png"
            style={{ position: "absolute", bottom: "-60px", zIndex: "-1" }}
          ></img>
        </Columns>
      </Section>

      <Section classes=" ">
        <Columns classes="is-variable is-8 ">
          <div className="column is-two-thirds columns is-multiline  remove-padding">
            <div className="column is-ful  remove-paddingl">
              <Columns classes=" remove-padding">
                <div className="column is-one-quarter  remove-padding">
                  <img
                    src="./images/boost.gif"
                    style={{ width: "120px" }}
                  ></img>
                </div>
                <div className="column is-three-quarters  remove-padding">
                  <h2 className="is-size-3 ">Boost performance</h2>
                  <br />
                  <p className="is-size-5 is-bold">
                    High performance plastic replaces steel shank.
                  </p>
                  <p className="is-size-5">
                    For over a hundred years, high heel insoles have been made
                    from rigid steel which makes it difficult to bear it for a
                    longer duration. We have replaced it with high performance
                    plastic and made world’s thinnest insole board for high
                    heels. It is lightweight, stronger, more stable and offers
                    even pressure distribution.{" "}
                  </p>
                </div>
              </Columns>
            </div>
            <div className="column is-full">
              <Columns classes=" remove-padding">
                <div className="column is-one-quarter  remove-padding">
                  <img
                    src="./images/nasa3.gif"
                    style={{ width: "160px" }}
                  ></img>
                </div>
                <div className="column is-three-quarters  remove-padding">
                  <h2 className="is-size-3 ">
                    6mm thick NASA grade memory foam
                  </h2>
                  <br />
                  <p className="is-size-5">
                    World’s thinnest insole board for high heels gave us the
                    leverage to use dual purpose cushioning in 6mm thickness.
                    The foam has high resiliency and is temperature and weight
                    sensitive allowing it to soften and follow exact contour of
                    the foot.
                  </p>
                </div>
              </Columns>
            </div>
          </div>
          <div
            className="column is-one-third columns is-multiline is-vcentered"
            style={{
              border: "1px solid rgba(0, 0, 0, 0.1)",
              boxShadow: "0px 0px 40px -10px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div className="column is-full has-text-centered">
              <img
                className=""
                src="./images/energy.gif"
                style={{ width: "120px", verticalAlign: "middle" }}
              ></img>
              <p
                className="is-size-5 is-bold "
                style={{ verticalAlign: "middle" }}
              >
                More Energy
              </p>
              <br />
            </div>
            <div className="column is-full has-text-centered">
              <img
                className=""
                src="./images/balance.gif"
                style={{ width: "120px", verticalAlign: "middle" }}
              ></img>
              <p
                className="is-size-5 is-bold "
                style={{ verticalAlign: "middle" }}
              >
                Better Balance
              </p>
              <br />
            </div>
            <div className="column is-full has-text-centered">
              <img
                className=""
                src="./images/comfort.gif"
                style={{ width: "120px", verticalAlign: "middle" }}
              ></img>
              <p
                className="is-size-5 is-bold "
                style={{ verticalAlign: "middle" }}
              >
                Higher Comfort
              </p>
              <br />
            </div>
          </div>
        </Columns>
        {/* <Columns classes=''>
        <div className = 'column is-half is-offset-one-quarter has-text-centered'>
          <br />
          <br />
          <Link to='/' className = 'button is-uppercase is-size-6 is-bold is-primary is-medium'> know more</Link>
        </div>
      </Columns> */}
      </Section>
      {/* 
    <Section classes=' '>
      <Columns classes='  is-multiline is-variable is-8'>
        <div className = 'column is-one-third has-text-left'>
          
          <img src = './icon1.png' style={{width: '80px', marginBottom: '1rem'}}></img>
          <p className='is-size-5 is-bold' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">Lightweight, reduced energy expenditure</p>
          <p className = 'is-size-6' style={{marginTop: '1rem'}}>lore asd as fgodgjicx welkrj llqwoi osf oifsdof rwervcx xcv.</p>
        </div>
        <div className = 'column is-one-third has-text-left'>
          
          <img src = './icon1.png' style={{width: '80px', marginBottom: '1rem'}}></img>
          <p className='is-size-5 is-bold' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">More stable, less wobbling</p>
          <p className = 'is-size-6' style={{marginTop: '1rem'}}>lore asd as fgodgjicx welkrj llqwoi osf oifsdof rwervcx xcv.</p>
        </div>
        <div className = 'column is-one-third has-text-left'>
          
          <img src = './icon1.png' style={{width: '80px', marginBottom: '1rem'}}></img>
          <p className='is-size-5 is-bold' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">Better pressure distribution, more comfort</p>
          <p className = 'is-size-6' style={{marginTop: '1rem'}}>lore asd as fgodgjicx welkrj llqwoi osf oifsdof rwervcx xcv.</p>
        </div>
        <div className = 'column is-one-third has-text-left'>
          
          <img src = './icon1.png' style={{width: '80px', marginBottom: '1rem'}}></img>
          <p className='is-size-5 is-bold' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">6mm nasa grade dual cushioning</p>
          <p className = 'is-size-6' style={{marginTop: '1rem'}}>lore asd as fgodgjicx welkrj llqwoi osf oifsdof rwervcx xcv.</p>
        </div>
        <div className = 'column is-one-third has-text-left'>
          
          <img src = './icon1.png' style={{width: '80px', marginBottom: '1rem'}}></img>
          <p className='is-size-5 is-bold' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">High performance plastic</p>
          <p className = 'is-size-6' style={{marginTop: '1rem'}}>lore asd as fgodgjicx welkrj llqwoi osf oifsdof rwervcx xcv.</p>
        </div>
        <div className = 'column is-one-third has-text-left'>
          
          <img src = './icon1.png' style={{width: '80px', marginBottom: '1rem'}}></img>
          <p className='is-size-5 is-bold' data-sal="slide-up" data-sal-delay="0" data-sal-duration="900">NASA grade memory foam</p>
          <p className = 'is-size-6' style={{marginTop: '1rem'}}>lore asd as fgodgjicx welkrj llqwoi osf oifsdof rwervcx xcv.</p>
        </div>
      </Columns>
      <Columns classes=''>
        <div className = 'column is-half is-offset-one-quarter has-text-centered'>
          <br />
          <br />
          <Link to='/' className = 'button is-uppercase is-size-6 is-bold is-primary is-medium'> know more</Link>
        </div>
      </Columns>
    </Section> */}

      <Section>
        <Columns classes="is-multiline is-variable is-8 is-vcentered">
          <div className="column is-full">
            <h2 className="is-size-1 has-text-centered has-marginbottom-2">
              Enhancing Comfort <br></br>& Performance
            </h2>
          </div>
          <div className="column is-half">
            <p className="is-size-4 has-marginbottom-1">
              Our Footwear was found to be 30-35% more comfortable by users when
              compared to standard stilettos, which can also be seen in the
              pressure profile results recorded on f-scan machine.
            </p>
            <ul>
              <li className="is-size-5">
                Increased contact area, hence{" "}
                <span className="is-bold">
                  reduced pressure per unit area by 12kpa
                </span>
              </li>
              <li className="is-size-5">
                Reduced peak pressure in forefoot area by 25.5%,{" "}
                <span className="is-bold">
                  which is one of the most common problem in stilettos.{" "}
                </span>
              </li>
            </ul>
          </div>
          <div className="column is-half">
            <img src="./images/study.png"></img>
          </div>
        </Columns>
      </Section>

      <Section>
        <Columns classes="is-multiline is-variable is-8 is-vcentered">
          <div className="column is-half" style={{ overflow: "visible" }}>
            <img
              src="./images/img3.png"
              style={{ boxShadow: "0px 0px 40px -10px rgba(0, 0, 0, 0.4)" }}
            ></img>
          </div>
          <div className="column is-half">
            <p className="is-bold is-size-4 txt-blue has-marginbottom-1">
              We are achieving the comfort of orthopedic shoes while still
              maintaining the aesthetics of luxury shoes.
            </p>
            <p className="is-size-4">
              Our insole system can increase the comfortability in your existing
              high heel constructions by 35%, without having to alter moulds of
              heels, platform and shoe last. However investment shall go into
              insole moulds.
            </p>
          </div>
          {/* <img
            alt=""
            src="./images/sep.png"
            style={{ position: "absolute", bottom: "-60px", zIndex: "-1" }}
          ></img> */}
        </Columns>
      </Section>

      <Section classes="has-marginbottom-2">
        <h3 className="is-size-3 has-text-centered">
          <Link to="/process">
            <span className="txt-blue" style={{ textDecoration: "underline" }}>
              Read more
            </span>
          </Link>{" "}
          about how Stimulate developed the
          <br />
          world’s most comfortable insole system.
        </h3>
        <img
          alt=""
          src="./images/sep.png"
          style={{ position: "absolute", bottom: "-90px", zIndex: "-1" }}
        ></img>
      </Section>
      <Section classes="has-marginbottom-2">
        <h2 className="is-size-1 has-text-centered has-marginbottom-2">
          In media
        </h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <a
            href="https://www.startuped.net/ebook/index.php/founder-stories/stimulate-designs-a-combination-of-fashion-and-comfort/"
            target="_blank"
            style={{ margin: "0 2rem" }}
          >
            <img
              src={"/images/startlogo.png"}
              alt="started logo"
              width="260px"
            />
          </a>
          <a
            href="https://www.whatsbrandnew.com/innovation-in-fashion-industry/"
            target="_blank"
            style={{ margin: "0 2rem" }}
          >
            <img src={"/images/wbnlogo.png"} alt="wbn logo" width="260px" />
          </a>
        </div>
      </Section>

      <Section classes="has-background-primary is-medium">
        <Columns classes="is-variable is-8 is-vcentered">
          <div className="column is-half">
            <h3 className=" is-size-4 has-text-white">
              Stimulate is a footwear R&D firm based out of India which has
              developed world’s most comfortable insole system for stilettos.
            </h3>
            <br />
            <Link to="/about">
              <h3
                className="is-bold is-size-4 has-text-white"
                style={{ textDecoration: "underline" }}
              >
                Know more about us.
              </h3>
            </Link>
          </div>
          <div
            className="column is-half"
            style={{ borderLeft: "1px solid white" }}
          >
            <Columns classes="is-multiline">
              <div className="column is-full remove-padding">
                <h3 className=" is-size-3 has-text-white"> Get In Touch</h3>
              </div>
              <div className="column is-full remove-padding">
                <p
                  className="is-size-4 has-text-white"
                  style={{ display: "flex", marginBottom: "1rem" }}
                >
                  <strong
                    className="has-text-white"
                    style={{ marginRight: "20px", width: "25%" }}
                  >
                    Address
                  </strong>
                  B-40, Sector-4, Noida,
                  <br /> Uttar Pradesh 201301, India
                </p>
                <p
                  className="is-size-4 has-text-white"
                  style={{ display: "flex", marginBottom: "1rem" }}
                >
                  <strong
                    className="has-text-white"
                    style={{ marginRight: "20px", width: "25%" }}
                  >
                    Phones
                  </strong>
                  +91 880 009 8135
                </p>
                <p
                  className="is-size-4 has-text-white"
                  style={{ display: "flex" }}
                >
                  <strong
                    className="has-text-white"
                    style={{ marginRight: "20px", width: "25%" }}
                  >
                    Email
                  </strong>
                  operations@stimulate.co.in
                </p>
              </div>
            </Columns>
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export default IndexPage
